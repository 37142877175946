import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useParams } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PersonList from '../components/personList';
import dayjs from 'dayjs';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import dummyShowUploadedImage from '../assert/saedafcz 1.png';
import dummyShowUploadedLogo from '../assert/Group 186.png';
import { brown } from '@mui/material/colors';
import { ThemeProvider } from "@mui/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { API_URLS } from '../utils/config';
import Alert from '@mui/material/Alert';
import FormLabel from '../common/FormLabel';

const defaultImageUrl = 'url/to/default/image.jpg';


const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: '#FCB98E',
    background: `url("./Group185.png")`,
    overflowY: "hidden",
    borderRadius: "2%",
    margin: "2%",
    flexDirection: 'column',
    alignItems: 'center',
  },
  circle: {
    position: "relative",
    marginTop: "-90px",
    marginLeft: "40px",
    borderRadius: "50%",
    width: "100px",
    height: "100px",
  },
  image: {
    borderRadius: '0px 0px 200px 200px',
    width: '100%',
    maxHeight: 300,
    resizeMode: 'cover',
    imageResolution: 'from-image'
  },
  customerFileBtn: {
    width: "100%",
    marginTop: "3%",
    '& input[type="file"]': {
      display: 'none'
    },
    '& label': {
      padding: "6px 12px",
      backgroundColor: "#FFFFFF",
      color: "#463636",
      borderRadius: "10px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      borderStyle: "groove",
      height: "30px",
      borderColor: "#FCB98E"
    },
    '& label:hover': {
      backgroundColor: "#FFFFFF"
    }

  }
}));



const theme = createTheme({
  palette: {
    primary: brown,
  },
});

const CreateOffer = () => {
  const classes = useStyles();
  const [selectedFileName, setSelectedFileName] = useState('');
  const [userList, setUserList] = useState([]);
  const [error, setError] = useState(null);
  const [offerSubtypeList, setOfferSubtypeList] = useState([]);
  const [offerSubtypeData, setOfferSubtypeData] = useState(null);
  const [terms, setTerms] = useState([]);
  const [displayTerms, setDisplayTerms] = useState('');
  const location = useLocation();
  const [selectedOfferTypeId, setSelectedOfferTypeId] = useState(null);
  const [quantity, setQuantity] = useState({});
  const [showPersonList, setShowPersonList] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedOfferSubtype, setSelectedOfferSubtype] = useState();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const [offerTypeId, setOfferTypeId] = useState('');
  const [offerTypeList, setOfferTypeList] = useState([]);
  const [offerSubTypeId, setOfferSubTypeId] = useState('');
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [subTypeDescription, setSubTypeDescription] = useState('');
  const [selectedOfferSubtypeName, setSelectedOfferSubtypeName] = useState('');
  const [selectedOfferTypeName, setSelectedOfferTypeName] = useState('');
  const { offerId } = useParams();
  const { reActiveOfferId } = useParams();
  const [showUploadedImage, setShowUploadedImage] = useState(null);
  const [showUploadedLogo, setShowUploadedLogo] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [fileError, setFileError] = useState('');
  const handleClose = () => setOpen(false);
  const [titleTouched, setTitleTouched] = useState(false);
  const [descriptionTouched, setDescriptionTouched] = useState(false);
  const [isSpecialOffer, setIsSpecialOffer] = useState(false);
  const [termTouched, setTermTouched] = useState(false);
  const [selectionMade, setSelectionMade] = useState(false);
  const [isFieldClicked, setIsFieldClicked] = useState(false);
  const [isImageFieldClicked, setIsImageFieldClicked] = useState(false);
  const [isTypeFieldClicked, setIsTypeFieldClicked] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [openSnackBarNew, setOpenSnackBarNew] = React.useState(false);
  const [subTypeImage, setSubTypeImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dynamicImageId, setDynamicImageId] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const navigate = useNavigate();

  const isReactiveTime = !!reActiveOfferId;

 


  const handleOpenModal = () => {
    setShowPersonList(true);
    handleOpen();
    setIsFieldClicked(true);
  };
  const handleBack = () => {
    navigate('/offer/offer-type');
  }
  const handleButtonClick = () => {
    setIsImageFieldClicked(true);
  };
  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  useEffect(() => {
       
    const fetchUserDetail =  () => {
        const token = localStorage.getItem('token');
        const id = localStorage.getItem('hotelId');
console.log("fetchUserDetail");
         fetch(`${API_URLS.BASE_URL}/hotel-name-logo/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                if(data.data.logo != null){
                  setShowUploadedLogo(data.data.logo);
                }
            })
            .catch((error) => {
                setError(error.message);
            });
    };


    fetchUserDetail();
}, []);
useEffect(() => {
  if (showUploadedImage === null){
    fetchOfferTypes();
    setShowUploadedImage(dummyShowUploadedImage);
   } 
 
}, [showUploadedImage]);

const fetchOfferTypes = async () => {
  const token = localStorage.getItem('token');
  const id = offerId;
console.log(id)
;
  try {
   

    const imageResponse = await fetch(`${API_URLS.BASE_URL}/offer-type/image/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!imageResponse.ok) {
      throw new Error(`HTTP error! Status: ${imageResponse.status}`);
    }

    const imageBlob = await imageResponse.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);


    setShowUploadedImage(imageObjectURL);
  } catch (error) {
    console.error('Error fetching image:', error);
  }
};

  const handleSelectUsers = (users) => {
    setSelectedUsers(users);
    setShowPersonList(true);
    setSelectionMade(users.length > 0);
  };

  const handleTitleBlur = () => {
    
      setTitleTouched(true);
   
  };

  const handleDescriptionBlur = () => {
    
      setDescriptionTouched(true);
    
  };


  const handleTermBlur = () => {
    if (displayTerms === '') {
      setTermTouched(true);
    }
  };

  const fetchOfferUserDetails = (id) => {

    const token = localStorage.getItem('token');

    fetch(`${API_URLS.BASE_URL}/user-list-by-offer/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const userIDs = data.data.map(entry => entry.user.id);
        // setSelectedUsers(userIDs);
      })

      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(() => {
    setOfferTypeId(offerId);
    const selectedSubtype = offerTypeList.find(subtype => subtype.id == offerId);
    if (selectedSubtype) {
      setSelectedOfferTypeName(selectedSubtype.name);
    }
  }, [offerId]);

  useEffect(() => {
    if (reActiveOfferId) {
      const numericId = reActiveOfferId.split('=')[1];
      fetchOfferUserDetails(numericId);
      fetchOfferDetails(numericId);
    }
  }, [reActiveOfferId]);

  const fetchImage = async () => {
    try {
      const token = localStorage.getItem('token');
      const id = reActiveOfferId.split('=')[1];
      const response = await fetch(`${API_URLS.BASE_URL}/offer/image/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },});
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const imageBlob = await response.blob();
      const imageUrl = URL.createObjectURL(imageBlob);
      setShowUploadedImage(imageUrl);
      setUploadedImage(imageUrl);
    } catch (error) {
      console.error("Failed to fetch image", error);
    }
  };

  const fetchOfferDetails = (id) => {

    const token = localStorage.getItem('token');

    fetch(`${API_URLS.BASE_URL}/offer/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {

        setTitle(data.data.title);
        setDescription(data.data.description);
        setOfferSubTypeId(data.data.subTypeName);
       //setShowUploadedImage(data.data.image);
        setSelectedFileName(data.data.image);
        console.log(data.data);
        console.log(data.data.termCondition);
        setDisplayTerms(data.data.termCondition);
        setSubTypeDescription(data.data.subTypeDescription)
        fetchImage();
        const selectedSubtype = offerSubtypeList.find(subtype => subtype.id == data.data.subTypeId);
        if (selectedSubtype) {
          setSelectedOfferSubtypeName(selectedSubtype.name);
        }
      })

      .catch((error) => {
        setError(error.message);
      });
  };
 
  useEffect(() => {
    const fetchUserList = () => {
      const token = localStorage.getItem('token');
      const hotelId = localStorage.getItem('hotelId');

      fetch(`${API_URLS.BASE_URL}/nearby?hotelId=${hotelId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setUserList(data.data);
          console.log(data.data)
        })
        .catch((error) => {
          setError(error.error);
        });
    };
    fetchUserList();
  }, []);

  useEffect(() => {
    const fetchOfferTypeList = () => {
      const token = localStorage.getItem('token');
      const hotelId = localStorage.getItem('hotelId');
  
      fetch(`${API_URLS.BASE_URL}/V1/List-of-offer-type`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setOfferTypeList(data.data);
          const searchParams = new URLSearchParams(location.search);
          const offerId = searchParams.get('offerId');
          const offerIdNum = parseInt(offerId, 10);
  
          const selectedOfferType = data.data.find(offerType => offerType.id === offerIdNum);
          if (selectedOfferType) {
            setSelectedOfferTypeName(selectedOfferType.name);
            const lowercaseSubtype = selectedOfferType.name.toLowerCase(); // Use selectedOfferType.name directly
            if (lowercaseSubtype.includes('birthday') || lowercaseSubtype.includes('anniversary') || lowercaseSubtype.includes('sunday')) {
              setIsSpecialOffer(true);
            }
          } else {
            setSelectedOfferTypeName('Offer Type ');
          }
          
          console.log(data.data);
        })
        .catch((error) => {
          setError(error.message); // Changed to error.message to correctly capture the error
        });
    };
    fetchOfferTypeList();
  }, []);
  
  useEffect(() => {
    if (selectedOfferTypeId && offerTypeList.length > 0) {
      console.log(selectedOfferTypeId + "," + offerTypeList)

    }
  }, [selectedOfferTypeId, offerTypeList]);

  useEffect(() => {
    const fetchOfferSubTypeList = () => {
      const token = localStorage.getItem('token');
      fetch(`${API_URLS.BASE_URL}/offer-sub-type-list`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setOfferSubtypeList(data.data);

        })
        .catch((error) => {
          setError(error.message);
        });
    };

    fetchOfferSubTypeList();

  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const offerId = searchParams.get('offerId');
    setSelectedOfferTypeId(offerId);
    // if (offerId == 4 || offerId == 6 || offerId == 8) {
    //   setIsSpecialOffer(true);
    // }
    const selectedOfferType = offerTypeList.find(offerType => offerType.id === selectedOfferTypeId);
          if (selectedOfferType) {
            setSelectedOfferTypeName(selectedOfferType.name);
            const lowercaseSubtype = selectedOfferTypeName.toLowerCase();
            if (lowercaseSubtype.includes('birthday') || lowercaseSubtype.includes('anniversary') || lowercaseSubtype.includes('sunday')) {
              setIsSpecialOffer(true);
            }
          }
  }, [location.search]);

  useEffect(() => {
    const fetchItemList = () => {
      const token = localStorage.getItem('token');
      const id = localStorage.getItem('hotelId');

      fetch(`${API_URLS.BASE_URL}/V1/item-by-hotel/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setItemList(data.data);
        })
        .catch((error) => {
          setError(error.message);
        });
    };

    fetchItemList();
  }, []);


  useEffect(() => {
    if (selectedOfferSubtype) {
      const token = localStorage.getItem('token');

      fetch(`${API_URLS.BASE_URL}/offer-sub-type/${selectedOfferSubtype}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setOfferSubtypeData(data.data);
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  }, [selectedOfferSubtype]);


  // const handleItemSelect = (event, fieldId) => {
  //   const temp = {}
  //   temp[fieldId] = event.target.value;
  //   const newone = { ...selectedItem, ...temp };
  //   setSelectedItem(newone);
  // };

  const handleItemSelect = (event, fieldId) => {
    const { value } = event.target;
    setSelectedItem({
      ...selectedItem,
      [fieldId]: value
    });
  };

  const handleQuntSelect = (value, fieldId) => {
    const temp = {}
    temp[fieldId] = value;
    setQuantity({ ...quantity, ...temp });
  };

  const handleStartDateChange = (newValue) => {
    console.log("in on change on date picker")
    setStartDate(newValue);
    console.log(newValue)
    console.log(startDate);
    setExpireDate(null);
  };

  const handleExpireDateChange = (newValue) => {
    setExpireDate(newValue);
  };

  const handleChange = (event) => {
    setSelectedOfferSubtype(event.target.value);
    setOfferSubTypeId(event.target.value);
    const selectedSubtype = offerSubtypeList.find(subtype => subtype.id === event.target.value);
    if (selectedSubtype) {
      setSelectedOfferSubtypeName(selectedSubtype.name);
    }
    if (subTypeImage) {
      setSubTypeImage("")
    }
    if (selectedSubtype.image) {
      console.log(selectedSubtype.image)
      setSubTypeImage(selectedSubtype.image);
      console.log(subTypeImage)
    }
  };

  const handleTouchField = () => {
    setIsTypeFieldClicked(true);
  };

  function findUserNameById(userId) {
    const user = userList.find(user => user.id === userId);
    return user ? user.userName : 'User not found';
  }

  function findSubTypeName(subtypeId) {
    const user = offerSubtypeList.find(user => user.id === subtypeId);
    return user ? user.name : 'selected subtype here';
  }

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };
  useEffect(() => {
    updateDisplayTerms();
  }, [terms]);

  const handleTermChange = (term) => {
    if (terms.includes(term)) {
      setTerms(terms.filter((t) => t !== term));
    } else {
      setTerms([...terms, term]);
    }
  };

  const buttonClicked = () => {
    if(reActiveOfferId){
      navigate('/history');
    }else{
    navigate('/offer/offer-type');
    }
  }

  const termsOptions = [
    '2 offers cannot be included.',
    // 'Reward cannot be exchanged for cash.',
    // 'Reward redeemable in-store only.',
    'Additional T&C apply.'
  ];

  const updateDisplayTerms = () => {
    setDisplayTerms(terms.join('\n'));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (title && description && displayTerms && selectedUsers && selectedOfferSubtype && startDate && expireDate) {
      setIsSubmitting(true);
    }

    if (selectedUsers.length == 0) {
      console.log(selectedUsers)
      return;
    }


    if (reActiveOfferId) {
      updateOffer();
    } else {
      createOffer();
    }
    
  }

  const createOffer = () => {


    if (!subTypeImage) {
      if (!title || !description  || !displayTerms || !selectedUsers || !selectedOfferSubtype || title.length > 30 || description.length > 60 || !startDate || (!expireDate && !isSpecialOffer)) {
        return;
      }
    }

    

    const formData = new FormData();
    const id = localStorage.getItem('hotelId');
    console.log("sdccscascascasc",startDate);
    if (subTypeImage) {
      const offer = {
        startDate: startDate,
        expireDate: expireDate,
        offerTypeId: offerTypeId,
        offerSubTypeId: offerSubTypeId,
        termCondition: displayTerms,
        user: selectedUsers,
        hotelId: id,
      }

      const offerBlob = new Blob([JSON.stringify(offer)], { type: 'application/json' });
      formData.append('offer', offerBlob);

      const emptyBlob = new Blob([''], { type: 'image/png' });
      formData.append('image', emptyBlob);
    } else {
      formData.append('image', uploadedImage);
      // const findItemName = (itemId) => {
      //   const selectedItemObject = itemList.find(item => item.id === itemId);
      //   return selectedItemObject ? selectedItemObject.name : "";
      // };
      const findItemName = (itemIds) => {
        if (!Array.isArray(itemIds)) {
          itemIds = [itemIds]; // Ensure itemIds is an array
        }
        const selectedItemNames = itemIds.map(itemId => {
          const selectedItemObject = itemList.find(item => item.id === itemId);
          return selectedItemObject ? selectedItemObject.name : "";
        });
        return selectedItemNames.filter(name => name !== "").join(", "); // Join names with a comma
      };
      const offer = {
        title: title,
        description: description,
        startDate: startDate,
        expireDate: expireDate,
        offerTypeId: offerTypeId,
        offerSubTypeId: offerSubTypeId,
        termCondition: displayTerms,
        user: selectedUsers,
        hotelId: id,
        subTypeDetails: offerSubtypeData.fields.map(field => ({
          display: field.display,
          item: findItemName(selectedItem[field.id]),
          value: quantity[field.id]
        }))
      };

      const offerBlob = new Blob([JSON.stringify(offer)], { type: 'application/json' });
      formData.append('offer', offerBlob);
    }
    const token = localStorage.getItem('token');


    fetch(`${API_URLS.BASE_URL}/add-offer`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
         
          setOpenSnackBar(true);
          setIsSubmitting(false);
         
        }
        return response.json();
      })
      .then(data => {
        clearForm();
        setOpenSnackBarNew(true);
        setIsSubmitting(false);
        const idMatch = data.message.match(/id\s*:\s*(\d+)/);
        const id = idMatch ? idMatch[1] : null;
        setTimeout(() => {
          navigate('/today-offer');
        }, 3000);
        if (id) {
          setDynamicImageId(id); const token = localStorage.getItem('token');

          return fetch(`${API_URLS.BASE_URL}/${id}/dynamic-image`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })

        }
   

      })

      .then(response => {
        if (!response.ok) {
         // throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.text(); // Assuming the response is text/html
      })
      // .then(html => {
      //   // Display the HTML in a modal pop-up
      //   displayModal(html);
      // })
      .catch(error => {
       
        setIsSubmitting(false);
       
      });
  };
  // function displayModal(htmlContent) {

  //   const modal = document.createElement('div');


  //   modal.innerHTML = htmlContent;


  //   modal.style.position = 'fixed';
  //   modal.style.top = '50%';
  //   modal.style.left = '50%';
  //   modal.style.transform = 'translate(-50%, -50%)';
  //   modal.style.backgroundColor = 'white';
  //   modal.style.padding = '20px';
  //   modal.style.zIndex = '1000';
  //   modal.style.borderRadius = '5px';
  //   modal.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';


  //   document.body.appendChild(modal);


  //   const closeButton = document.createElement('button');
  //   closeButton.innerText = 'Close';
  //   closeButton.onclick = function () {
  //     document.body.removeChild(modal);
  //   };
  //   modal.appendChild(closeButton);


  //   window.onclick = function (event) {
  //     if (event.target === modal) {
  //       document.body.removeChild(modal);
  //     }
  //   };
  // }
  const clearForm = () => {
    setSubmitted(false)
    setTitle('');
    setDescription('');
    setSelectedOfferSubtype('');
    setUploadedImage(" ");
    setSelectedFileName(" ")
    setStartDate(null);
    setExpireDate(null);
    setOfferTypeId('');
    setOfferSubTypeId('');
    setItemList([]);
    setSelectedItem({});
    setSelectedUsers([]);
    setDropdownOpen(true);
    setDisplayTerms('');
    setOfferSubtypeData('');
    setSelectedOfferSubtype(null);
    setTerms([]);
    setTitleTouched(false);
    setDescriptionTouched(false)
  }
  const updateOffer = () => {
    if (!selectedUsers || !startDate || (!expireDate && !isSpecialOffer)) {
      return;
    }
    const token = localStorage.getItem('token');
    const updateOffer = {
      user: selectedUsers,
      startDate: startDate,
      expireDate: expireDate
    }
    const offerId = reActiveOfferId.split('=')[1];

    fetch(`${API_URLS.BASE_URL}/re-active-offer/${offerId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updateOffer),
    })
      .then((response) => response.json())
      
      .then((data) => {
        setTimeout(() => {
          navigate('/today-offer');
        }, 500);
      if (offerId) {
        setDynamicImageId(offerId); const token = localStorage.getItem('token');

        return fetch(`${API_URLS.BASE_URL}/${offerId}/dynamic-image`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })

      }
    })
      .catch((error) => {
        // Handle error
      });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB
    const validFormats = ['image/png', 'image/jpeg', 'image/jpg'];

    if (!file) {
      setFileError('Please select a file.');
      return;
    }

    if (!validFormats.includes(file.type)) {
      setFileError('Invalid file format. Please upload a PNG, JPEG, or JPG file.');
      return;
    }

    if (file.size > maxSize) {
      setFileError('File size should be less than or equal to 2MB.');
      return;
    }

    setFileError(''); 
    setUploadedImage(file);
    showImageUpload(file);
    if (file) {
      setSelectedFileName(file.name);
    }
  };

  const showImageUpload = (file) => {
    setShowUploadedImage(URL.createObjectURL(file));
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={6} style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
          <ArrowBackIcon variant="button"  onClick={buttonClicked} style={{color:"white",marginTop:"20px", height: "max-content",marginLeft:"-90px",cursor:"pointer"}} >  </ArrowBackIcon>
            <h3 style={{ color: "white", display: 'flex', height: "max-content", position: "absolute", marginTop: "50px" }}>{selectedOfferTypeName}</h3>

            <Card sx={{height: '60px%', width: '65%', justifyContent: 'center', alignItems: 'center', marginTop: "20%", marginBottom: "15%",marginLeft:"50px" }}>

              {!subTypeImage && <div>
                {showUploadedImage && (
                  <img className={classes.image} src={showUploadedImage} alt="Uploaded" />
                )}
                {showUploadedLogo ? (
                            <img src={showUploadedLogo} alt="Hotel Logo" className={classes.circle} />
                        ) : (
                            <img src="/MaskGroup.png" alt="Default Hotel Logo" className={classes.circle}/>
                        )}
               
                <div style={{ alignItems: "center", textAlign: "center" }}>
                  <p style={{ fontWeight: 'bold', color: "#FF6B09", fontSize: "30px", display: "initial" }}>Surprise</p><br />
                  <p style={{ fontWeight: 'bold', color: "#463636", fontSize: "20px", overflowWrap: 'anywhere', margin: "0 7%", padding: "1%", textAlign: "center", marginTop: "4%" }}>{title || "Your Offer title text goes here"}</p>
                  <p style={{ fontWeight: 'bold', color: "#463636", fontSize: "20px", overflowWrap: 'anywhere', padding: "1%", textAlign: "center", marginTop: "1%" }}> Saving Alert : {findSubTypeName(offerSubTypeId) || "Your offer subtype here"}</p>
                  <p style={{ fontWeight: 'bold', fontSize: "13px", color: "#463636", overflowWrap: 'anywhere', margin: "0 7%", textAlign: "center", padding: "1%", textAlign: "center", marginTop: "1%" }}>{description || "Your offer description text goes here."}</p>
                  < hr style={{
                    border: 'none',
                    borderTop: '1px dotted #f00',
                    color: 'black',
                    backgroundColor: '#fff',
                    height: '2px',
                    width: '100%'
                  }} />
                  <div style={{ textAlign: "center" }}>
                  {!isSpecialOffer &&<p>Expires on {expireDate ? dayjs(expireDate).format('DD MMM YYYY') : "(Expire date)"}</p>}
                    {isSpecialOffer && <p>Expires on {startDate ? dayjs(startDate).format('DD MMM YYYY') : "(Expire date)"}</p>}
                    {displayTerms ? (
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        {displayTerms.split('. ').map((term, index) => (
                          <li key={index} style={{ marginBottom: "10px" }}>
                            {term.endsWith('.') ? term : `${term}.`}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>Term & conditions</p>
                    )}
                  </div>
                </div>
              </div>
              }
              {subTypeImage && <div>
                <img src={`data:image/jpeg;base64,${subTypeImage}`} alt="Uploaded" style={{ height: "100%", width: "100%" }} />
                <div style={{ textAlign: "center" }}>
                  <p>Expires on {expireDate ? dayjs(expireDate).format('DD MMM YYYY') : "(Expire date)"}</p>
                  {displayTerms ? (
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {displayTerms.split('. ').map((term, index) => (
                        <li key={index} style={{ marginBottom: "10px" }}>
                          {term.endsWith('.') ? term : `${term}.`}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>Term & conditions</p>
                  )}
                </div></div>}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ backgroundColor: '#463636', padding: "2%", marginTop: "0%", margin: "0%", height: '100vh', overflowY: 'auto', borderRadius: "20px" }}>
              <form onSubmit={handleSubmit}>
                <div style={{ backgroundColor: '#FEF0E7', padding: "2%", margin: "1%", width: "auto", borderRadius: "15px" }}>
                <FormLabel label="Person List" isRequired={true} /><br />
                  <input type="text" onClick={handleOpenModal} value={selectedUsers.map(userId => findUserNameById(userId))} style={{ width: "99%", height: '40px', marginTop: "10px", borderRadius: "10px", borderColor: "#FCB98E" }} />
                  {(isFieldClicked && !selectionMade) || (selectedUsers.length == 0 && submitted) && <p style={{ color: '#d32f2f', fontSize: "0.75rem", marginLeft: "15px" }}>required*</p>}
                  {showPersonList && (<Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ width: "70%", marginLeft: "20%", marginTop: "5%", overflow: 'auto','&::-webkit-scrollbar': {
                        width: '0 !important', 
                      },
                      '-ms-overflow-style': 'none', 
                      scrollbarWidth: 'none', 
                     }} >
                    <div>
                      <button onClick={handleClose} style={{ float: 'right', marfontWeight: 'bold', marginTop: "1%", marginRight: "1%" }}>X</button>
                      <PersonList onSelectUsers={handleSelectUsers} selectedUsers={selectedUsers} onCloseModal={handleClose} selectedstartdate={startDate} selectedofferType={offerTypeId} />
                    </div>
                  </Modal>)}
                </div>

                <div style={{
                  backgroundColor: '#FEF0E7',
                  padding: "2%",
                  margin: "1%",
                  width: "auto",
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: "15px"
                }}>
                   <FormLabel label="Type" isRequired={true} />
                  <Select
                    value={selectedOfferSubtype}
                    onChange={handleChange}
                    disabled={isReactiveTime}
                    style={{ height: '45px', marginTop: "1%", borderRadius: "10px", borderColor: "#FCB98E !important" }}
                    placeholder={selectedOfferSubtypeName}
                    label={selectedOfferSubtypeName}
                    onBlur={handleTouchField}
                  >
                    {offerSubtypeList.map((offerSubType) => (
                      <MenuItem key={offerSubType.id} value={offerSubType.id} selected={offerSubType.id == selectedOfferSubtype}>
                        {offerSubType.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {(((isTypeFieldClicked && !selectedOfferSubtypeName) || (submitted && !selectedOfferSubtypeName)) && !isReactiveTime) && <p style={{ color: '#d32f2f', fontSize: "0.75rem", marginLeft: "15px" }}>required*</p>}
                  <div style={{ display: 'flex', flexDirection: 'row', padding: '2%', flexWrap: 'wrap' }}>
                    {offerSubtypeData && !offerSubtypeData.image && offerSubtypeData.fields
                      .sort((a, b) => a.id - b.id)
                      .map(field => (
                        <div key={field.id} style={{ display: 'flex', marginBottom: '10px', textAlign: 'center', marginRight: '20px' }}>
                          {field.display}
                          {field.item !== null && (
                            <Select
                            multiple  // Enable multi-select
                            value={selectedItem[field.id] || []}  // Use array for multi-select
                            onChange={(e) => { handleItemSelect(e, field.id) }}
                            style={{ flex: '1', marginLeft: '10px', minWidth: '100px', maxWidth: '200px', minHeight: '30px', borderRadius: '10px', backgroundColor: 'white' }}
                            renderValue={(selected) => (
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {selected.map((value) => (
                                  <div key={value} style={{ margin: '2px', padding: '2px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f0f0f0' }}>
                                    {itemList.find(item => item.id === value)?.name}
                                  </div>
                                ))}
                              </div>
                            )}
                          >
                            {itemList.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          )}
                          {field.quantity !== null && (
                            <input type="text"
                              value={quantity[field.id]}
                              onChange={(e) => handleQuntSelect(e.target.value, field.id)}
                              style={{ marginLeft: '10px', minWidth: "5px", width: "30px !important", height: "25px", borderRadius: "10px" }}
                            />
                          )}
                        </div>
                      ))}
                  </div>

                </div>
                {!subTypeImage && <div style={{ backgroundColor: '#FEF0E7', padding: '2%', margin: '1%', width: 'auto', borderRadius: "15px" }}>
                  <label style={{ fontWeight: 'bold', color: '#463636' }}>Upload Image</label><br />
                  <div className={`input-group ${classes.customerFileBtn}`}>
                    <label style={{width: "95%", borderRadius: 10, marginBottom: 15}} className="input-group-text" htmlFor="inputGroupFile">
                      <span style={{ marginRight: "3%",alignSelf:'center' }}>{selectedFileName ? selectedFileName : 'Upload Your Image'}</span>
                      <input type="file" accept="image/*" onChange={handleImageUpload} className="form-control" id="inputGroupFile" disabled={isReactiveTime} onClick={handleButtonClick} />
                    </label>
                   
                    {fileError && <p style={{ color: '#d32f2f', fontSize: "0.75rem", marginLeft: "15px" }}>{fileError}</p>}
                    <p style={{ fontSize: "14px" }}>Recommended size 512 x 512 px in JPG, JPEG, or PNG format up to a maximum size of 2MB.</p>
                  </div>
                </div>
                }
                {!subTypeImage && <div style={{ backgroundColor: '#FEF0E7', padding: "2%", margin: "1%", width: "auto", borderRadius: "15px" }}>
                <FormLabel label="Title" isRequired={true} /><br />
                  {/* <TextField id="outlined-basic" variant="outlined"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    disabled={isReactiveTime}
                    onBlur={handleTitleBlur}
                    error={(title === '' && titleTouched) || (title === '' && submitted)}
                    helperText={((title === '' && titleTouched) || (title === '' && submitted)) ? 'required*' : ' '}
                    sx={{ width: "100%", borderRadius: "30px !important", marginTop: "10px", padding: "0px !important" }}
                  /><br />
                  <FormLabel label="Description" isRequired={true} /><br />
                  <TextField id="outlined-basic" variant="outlined"
                    value={description}
                    multiline
                    rows={3}
                    onChange={(event) => setDescription(event.target.value)}
                    disabled={isReactiveTime}
                    onBlur={handleDescriptionBlur}
                    error={(description === '' && descriptionTouched) || (description === ' ' && submitted)}
                    helperText={((description === '' && descriptionTouched) || (description === ' ' && submitted)) ? 'required*' : ' '}
                    sx={{ width: "100%", borderRadius: "10px !important", marginTop: "10px", padding: "0px !important" }}
                  /> */}

<TextField
        id="outlined-basic"
        variant="outlined"
        value={title}
        onChange={(event) => {
          const newValue = event.target.value;
          if (newValue.length <= 30) {
            setTitle(newValue);
            setTitleError('');
          } else {
            setTitleError('Title cannot be more than 30 characters');
          }
        }}
        disabled={isReactiveTime}
        onBlur={handleTitleBlur}
        error={(title === '' && titleTouched) || (title === '' && submitted) || titleError !== ''}
        helperText={
          (title === '' && titleTouched) || (title === '' && submitted)
            ? 'required*'
            : titleError !== ''
            ? titleError
            : ' '
        }
        sx={{ width: "100%", borderRadius: "30px !important", marginTop: "10px", padding: "0px !important" }}
      /><br /><br />
      <FormLabel label="Description" isRequired={true} /><br />
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={description}
        multiline
        rows={3}
        onChange={(event) => {
          const newValue = event.target.value;
          if (newValue.length <= 60) {
            setDescription(newValue);
            setDescriptionError('');
          } else {
            setDescriptionError('Description cannot be more than 60 characters');
          }
        }}
        disabled={isReactiveTime}
        onBlur={handleDescriptionBlur}
        error={(description === '' && descriptionTouched) || (description === '' && submitted) || descriptionError !== ''}
        helperText={
          (description === '' && descriptionTouched) || (description === '' && submitted)
            ? 'required*'
            : descriptionError !== ''
            ? descriptionError
            : ' '
        }
        sx={{  width: "100%", borderRadius: "10px !important", marginTop: "10px", padding: "0px !important" }}
      />
                </div>
                }
                <div style={{ backgroundColor: '#FEF0E7',borderRadius:'20px', padding: '3%', margin: '2%', width: '91%' }}>
                <FormLabel label="Start Date" isRequired={true} /><br />
                  <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ marginBottom: "35px !important" }}>
                    <DemoContainer components={['DatePicker']} >
                      <DatePicker
                        value={startDate}
                        onChange={handleStartDateChange}
                        disablePast
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ width: "100%", overflowY: "hidden" }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {(submitted && !startDate) && <p style={{ color: '#d32f2f', fontSize: "0.75rem", marginLeft: "15px" }}>required*</p>}<br />
                   {!isSpecialOffer &&  <FormLabel label="Expire Date" isRequired={true} />}<br />
                 {!isSpecialOffer && <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']} >
                      <DatePicker value={expireDate} onChange={handleExpireDateChange}
                        minDate={startDate} disablePast slotProps={{ textField: { size: 'small' } }} sx={{ width: "100%", height: "45px !important", overflowY: "hidden", paddingBottom: "2px" }} />
                    </DemoContainer>
                  </LocalizationProvider>}
                  {(submitted && !expireDate && !isSpecialOffer) && <p style={{ color: '#d32f2f', fontSize: "0.75rem", marginLeft: "15px" }}>required*</p>}<br />
              
                </div>
                <div style={{ backgroundColor: '#FEF0E7', padding: '2%', margin: '1%', width: 'auto', borderRadius: "15px" }}>
                <FormLabel label="Term & Condition" isRequired={true} /><br />
                  <TextField
                    label="Selected Terms"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={displayTerms}
                    onBlur={handleTermBlur}
                    error={(displayTerms === '' && termTouched) || (displayTerms === '' && submitted)}
                    helperText={((displayTerms === '' && termTouched) || (displayTerms === '' && submitted)) ? 'required*' : ' '}
                    onChange={updateDisplayTerms}
                    disabled={isReactiveTime}
                    style={{ backgroundColor: '#FEF0E7', borderStyle: "groove", borderColor: "#FCB98E", borderRadius: "40px" }}
                  />
                  <div>
                    {termsOptions.map((term, index) => (
                      <div style={{ backgroundColor: "white", border: "2px", borderStyle: "groove", margin: "0.5%", padding: "0.5%", borderColor: "#FCB98E", borderRadius: "10px" }}>
                        <FormControlLabel
                          key={index}
                          control={<Checkbox checked={terms.includes(term)} onChange={() => handleTermChange(term)} />}
                          label={term}
                          disabled={isReactiveTime}
                          style={{ marginTop: "2px", borderRadius: "10px" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <Button variant="contained" style={{ width: "48%", margin: "1%", backgroundColor: "#FCB98E" }} onClick={handleBack}>Back</Button>
                <Button variant="contained" type="submit" disabled={isSubmitting} style={{ width: "49%", backgroundColor: "#FCB98E" }}>Submit</Button>
              </form>
            </div>
          </Grid>
        </Grid>
        
        {/* <Snackbar
          open={openSnackBarNew}
          autoHideDuration={5000}
          onClose={handleSnackBarClose}
          message='offer Created!!'
          action={action}
          
        />
        {error && (
          <Snackbar
            open={openSnackBar}
            autoHideDuration={5000}
            onClose={handleSnackBarClose}
            message={error}
            action={action}
          />
        )} */}
        <Snackbar
          open={openSnackBarNew}
          autoHideDuration={5000}
          onClose={handleClose}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
           Offer Created Successfully.
          </Alert>
        </Snackbar>
       
        {error && (
          <Snackbar
            open={openSnackBar}
            autoHideDuration={5000}
            onClose={handleClose}

            action={action}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: '100%' }}
            >
              {error}
            </Alert>
          </Snackbar>
        )}
      </div>
    </ThemeProvider>
  );
};

export default CreateOffer;



